import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { AuthService } from 'src/app/services/auth'
import { LoaderService } from 'src/app/services/loader';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit {

  model = {
    email: '',
    password: '',
    role: 'superAdmin'
  };

  errors: any = {};
  alert: string;

  constructor(
    private http: HttpClient,
    private router: Router,
    protected auth: AuthService,
    private loader: LoaderService) { }

  ngOnInit() {
  }

  login() {
    this.errors = {};
    this.loader.state(true);
    this.http.post('auth/login', this.model).subscribe(data => {
      this.loader.state(false);
      // @ts-ignore
      this.auth.login(data);
      this.router.navigate(['portal/organization']);
    }, error => {
      this.loader.state(false);
      if (error.status === 422) {
        this.errors = error.error.errors;
        //console.log(this.errors);
      }
    });
  }
  
}