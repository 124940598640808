import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { GuestLayoutComponent } from './modules/guest-layout/guest-layout.component';
import { PortalLayoutComponent } from './modules/portal-layout/portal-layout.component'
import { LoginComponent } from './modules/guest-layout/login/login.component';
import { ForgotPasswordComponent } from './modules/guest-layout/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './modules/guest-layout/reset-password/reset-password.component';

import { GuestGuard } from './guards/guest.guard';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [

  {
    path: '',
    component: GuestLayoutComponent,
    canActivate: [GuestGuard],
    children: [
      { path: '', component: LoginComponent },
      { path: 'forgot-password', component: ForgotPasswordComponent },
      { path: 'password/reset', component: ResetPasswordComponent },
    ]
  },

  {
    path: '',
    component: PortalLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'portal', loadChildren: './modules/portal-layout/portal-module#PortalModule'
      }
    ]
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }