import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services/auth';

@Injectable()
export class GuestGuard implements CanActivate {

    constructor(private router: Router, private auth: AuthService) { }
    
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        //console.log(this.auth.guest());
        if (this.auth.guest()) {
            return true;
        }
        let user = this.auth.user();
        // logged in so return false
        this.router.navigate(['/portal/organization']);
        return false;
    }
}