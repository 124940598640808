import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { AuthService } from 'src/app/services/auth'
import { LoaderService } from 'src/app/services/loader';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})

export class ResetPasswordComponent implements OnInit {

  model: any = {};
  token: any = {};
  errors: any = {};

  constructor(
    private router: Router,
    private http: HttpClient,
    private auth: AuthService,
    private loader: LoaderService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.token = params.token || null;
      if (!this.token) {
        return;
      }
    });
  }

  resetPassword() {
    this.errors = {};
    this.loader.state(true);
    const params: any = {
      token: this.token
    };
    this.http.post('auth/password/reset', this.model, { params }).subscribe(
      (data: any) => {
        this.loader.state(false);
        this.router.navigate(['portal/organization']);
      },
      error => {
        this.loader.state(false);
        if (error.status === 422) {
          this.errors = error.error.errors;
        }
      }
    );
  }

}