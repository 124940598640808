import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { AuthService } from 'src/app/services/auth';
import { LoaderService } from 'src/app/services/loader';

@Component({
  selector: 'app-portal-layout',
  templateUrl: './portal-layout.component.html',
  styleUrls: ['./portal-layout.component.css']
})

export class PortalLayoutComponent implements OnInit {
  
  public isCollapsed = true;
  
  constructor(
    private http: HttpClient,
    protected auth: AuthService,
    private router: Router,
    private loader: LoaderService
  ) { }

  ngOnInit() {
  }

  logout() {
    if (confirm('Are you sure you want to logout?')) {
      this.http.get('user/logout').subscribe(data => {
        this.auth.logout();
        this.router.navigate(['/']);
      }, error => {

      });
    }
  }

}