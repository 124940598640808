import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { AuthService } from 'src/app/services/auth'
import { LoaderService } from 'src/app/services/loader';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})

export class ForgotPasswordComponent implements OnInit {

  model: any = {
    role: 'superAdmin'
  };
  errors: any = {};

  constructor(
    private http: HttpClient,
    private router: Router,
    protected auth: AuthService,
    private loader: LoaderService
  ) { }

  ngOnInit() {
  }

  forgotPassword() {
    this.errors = {};
    this.loader.state(true);
    this.http.post('auth/forgot/password', this.model).subscribe(
      data => {
        this.loader.state(false);
      },
      error => {
        this.loader.state(false);
        if (error.status === 422) {
          this.errors = error.error.errors;
          //console.log(this.errors);
        }
      }
    );
  }

}