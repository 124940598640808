import { Injectable, Inject } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { AuthService } from './auth';
import { tap } from 'rxjs/operators';
import { AlertService } from './alert';
import { Router } from '@angular/router';

@Injectable()
export class RequestInterceptorService implements HttpInterceptor {

    constructor(
        private auth: AuthService,
        private router: Router,
        private alertService: AlertService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // document.getElementById('CustomLoader').innerHTML = `<div id="preloader"><div id="loader"></div></div>`;

        const apiReq = req.clone(
            {
                url: `${environment.apiUrl}/${req.url}`,
                headers: new HttpHeaders({
                    'x-authtoken': this.auth.token()
                })
            });

        return next.handle(apiReq).pipe(tap(event => {
            if (event instanceof HttpResponse) {
                // document.getElementById('CustomLoader').innerHTML = '';
                if (event.body.message) {
                    this.alertService.state({ text: event.body.message });
                }
            }
        },
            error => {
                // document.getElementById('CustomLoader').innerHTML = '';
                //console.log('error in interceptor', error);
                // console.error(error);

                if (error.status === 422) {
                    this.alertService.state({ text: 'Please correct the highlighted inputs.', type: 'danger' });
                    return;
                }

                if (error.status === 406) {
                    this.alertService.state({ text: error.error.message || 'Internal server error', type: 'danger' });
                    return;
                }

                if (error.error) {
                    this.alertService.state({ text: error.error.message || 'Internal server error', type: 'danger' });
                }

                // setTimeout(() => {
                //     this.indicator.spinner(false);
                // }, 2000);

                // const info = new HttpInfo(error.status, error.error.error_description || error.statusText, 0);

                if ((error.status === 401)) {
                    // info.message = 'Session is invalid or expired. Please try loggin in again.';

                    this.auth.logout();

                    setTimeout(() => {
                        this.router.navigate([''], { queryParams: { ref: 'user' } });
                    }, 500);
                    this.alertService.state({ text: error.error.message || 'Internal server error', type: 'danger' });
                    return;
                }
            }));
    }
}