import { BehaviorSubject } from 'rxjs';

interface AlertItem {
    text: string;
    type?: string;
}

export class AlertService {
    attach = new BehaviorSubject<AlertItem>(null);
    state(s: AlertItem) {
        if (!s.type) {
            s.type = 'success';
        }
        this.attach.next(s);
    }
}