import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { GuestLayoutComponent } from './modules/guest-layout/guest-layout.component';
import { PortalLayoutComponent } from './modules/portal-layout/portal-layout.component';

import { AlertService } from './services/alert';
import { AuthService } from './services/auth';
import { RequestInterceptorService } from './services/http.interceptor';

import { AuthGuard } from './guards/auth.guard';
import { GuestGuard } from './guards/guest.guard';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';

import { LoginComponent } from './modules/guest-layout/login/login.component';
import { LoaderComponent } from './shared/loader/loader.component';
import { ForgotPasswordComponent } from './modules/guest-layout/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './modules/guest-layout/reset-password/reset-password.component';


@NgModule({
  declarations: [
    AppComponent,
    GuestLayoutComponent,
    PortalLayoutComponent,
    LoginComponent,
    LoaderComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    FormsModule,
    HttpClientModule
  ],
  providers: [
    AlertService,
    AuthGuard,
    GuestGuard,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    AuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptorService,
      multi: true
    },
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }